body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  /* background-image: linear-gradient(#e1f5fe, #c2cfd6); */
  /* background-image: linear-gradient(rgb(225, 245, 254, 0.5), rgb(225, 245, 254, 1)); */
  background-image: url("https://firebasestorage.googleapis.com/v0/b/wedding-fc9f4.appspot.com/o/background-marble.jpeg?alt=media&token=e0f141df-2845-4f30-bc26-cec4202154e3");
  background-size: cover

}